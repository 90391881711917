import { Link, useHistory } from "react-router-dom"
import { CameraOperationState } from "../../../components/CameraOperationState/CameraOperationState"
import { humanDate } from "../../../utils/time"
import { Delete, Edit, Settings } from "@material-ui/icons"
import TableRowAction from "../../../components/Table/TableRowAction"
import { useQueryClient } from "react-query"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { toaster } from "../../../utils/toaster"
import { Switch } from "@mui/material"
import { useState } from "react"

/**
 * Show a single row in the list of cameras.
 * @param {CMCameraResponse} camera The camera information.
 * @param {?CameraDTO} cameraData Basic info from the data API about the camera.
 * @param {boolean} showSystemColumn True to show to which system the camera is attached to, false to hide it.
 *
 * @return {JSX.Element}
 * @constructor
 */
const TableRow = function ({ camera, cameraData, showSystemColumn }) {
  const api = useCameraManagementAPI()
  const queryClient = useQueryClient()
  const history = useHistory()
  const [cameraDisabled, setCameraDisabled] = useState(camera?.is_disabled)
 
  // Delete a camera
  const deleteCamera = async (camera: CMCamera) => {
    if (window.confirm(`Are you sure you want to delete camera "${camera.name}" at "${camera.locality}"?`)) {

      // Create camera and invalidate the list on success
      const promise = api.deleteCamera(camera.uuid)
        .then(() => queryClient.invalidateQueries("CameraManagement:List"))

      // Show a friendly toaster
      await toaster.promise(promise, "Camera deleted successfully")
    }
  }

  const toggleCameraState = async (camera) => {
    if (window.confirm(`Are you sure you want to ${cameraDisabled ? 'enable' : 'disable'} camera "${camera.name}" at "${camera.locality}"?`)) {

      // Create camera and invalidate the list on success
      const promise = api.disableCamera(camera.uuid)
        .then((val) => {
          if (val?.data?.success) {
            setCameraDisabled(!cameraDisabled)
          }
          queryClient.invalidateQueries("CameraManagement:List")
        })

      // Show a friendly toaster
      await toaster.promise(promise, `Camera ${cameraDisabled ? 'enabled' : 'disabled'} successfully`)
    }
  }

  return (
    <tr key={camera.uuid}>
      {showSystemColumn && <td>{camera.system}</td>}
      <td> <Link to={`/cameras/dashboard/${camera?.system_id}/${camera.camera_id}`}> {camera.name}{camera.is_virtual ? " (virtual)" : ""}</Link></td>
      <td>{camera.locality}</td>
      <td><CameraOperationState camera={cameraData} position="float-right" />{camera.state}</td>
      <td className="text-center">{humanDate(camera.creation_date)}</td>
      <td className="text-center confirm">
        <TableRowAction
          actions={[
            { icon: <Edit />, title: "Edit camera", setState: () => history.push(`/admin/cm/edit/${camera.uuid}`) },
            { icon: <Settings />, title: "Advanced camera settings", setState: () => history.push(`/admin/cm/advanced/${camera.uuid}`) },
            { icon: <Delete />, title: "Delete camera", setState: () => deleteCamera(camera) },
            { icon: <Switch checked={!cameraDisabled} size="small" />, title: `${cameraDisabled ? 'Enable' : 'Disable'} camera`, setState: () => toggleCameraState(camera) }
          ]}
        />
      </td>
    </tr>
  )
}

export default TableRow
