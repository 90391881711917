import 'page.scss'
import React, { Suspense } from "react"
import { Redirect, Switch } from "react-router-dom"
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout"
import Error404 from "./pages/Errors/Error404"
import { LocationsPage } from "./pages/Locations/LocationsPage"
import { CamerasPage } from "./pages/Cameras/CamerasPage"
import { CameraDetails } from "./pages/Cameras/CameraDetails"
import { ReportsFormPage } from "./pages/Reports/ReportsFormPage"
import { UserManagement } from "./pages/UserManagement/index"
import { AddUser } from "./pages/UserManagement/AddUser/index"
import { Tenants } from "./pages/Tenants/index"
import { AddTenant } from "./pages/Tenants/AddTenant/index"
import { buildReportLink, ReportTypes } from "./pages/Reports/Reports"
import CameraManagementDashboard from "./pages/CameraManagement"
import CameraManagementForm from "./pages/CameraManagement/form"
import UploadFilesDashboard from "./pages/Files"
import UploadFiles from "./pages/Files/Upload"
import ProcessBatch from "./pages/Files/Process"
import EditFiles from "./pages/Files/EditFiles"
import AddFilesToBatch from "./pages/Files/AddFilesToBatch"
import EventLogDashboard from "./pages/EventLog"
import EventsPage from "./pages/Events/EventsPage"
import EventsLayout from "./pages/Events/EventsLayout"
import MatrixLayout from "./pages/ODMatrix/MatrixLayout"
import MatrixPage from "./pages/ODMatrix/MatrixPage"
import EventsDashboard from "./pages/EventManagement/EventsDashboard"
import Index from "./pages/EventManagement"
import AllEvents from "./pages/Events/AllEvents"
import MultiCameraIndex from "./pages/MultiCameras"
import MultiCameras from "./pages/MultiCameras/Report/MultiCameras"
import MCResLayout from "./pages/MultiCameras/Report/MCResLayout"
import PcuSettings from "./pages/Settings/PcuSettings"
import DetailsWrapper from "./pages/Cameras/DetailsWrapper"
import NewReportForm from "./pages/Reports/NewReportForm"
import BillingComp from './pages/Billing'
import MultiSceneDashboard from './pages/Multiscene'
import MultiSceneManagementForm from './pages/Multiscene/form'
import LiveStream from './pages/CameraManagement/LiveStream'
import Documentation from './pages/Documentation/Documentation'
import AdvancedSetting from './pages/CameraManagement/AdvancedSetting'

const BasePage = () => (
  <EventsLayout>
    <MatrixLayout>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>

          {/* Redirect from root URL to /locations */}
          <Redirect exact from="/" to="/locations" />

          {/* The map */}
          <ContentRoute path="/locations" component={LocationsPage} auditName="Locations" />

          {/* The list and details of cameras*/}
          <ContentRoute path="/cameras/:subpage/:systemID/:cameraID" component={DetailsWrapper} skipAudit />
          <ContentRoute path="/cameras" component={CamerasPage} auditName="Cameras" />

          {/* Reports */}
          <ContentRoute path="/reports" exact component={NewReportForm} auditName="Reports form" />
          {ReportTypes?.map(type => (
            <ContentRoute key={type.path} path={buildReportLink(type.handler)} component={type.handler} auditName={`Report: ${type.name}`} />
          ))}

          {/* Multi-camera report */}
          <ContentRoute path="/multi_camera_report" exact component={MultiCameras} auditName="MultiCamera" />
          <ContentRoute path="/new/multi_camera_report" exact component={MultiCameraIndex} auditName="MultiCamera" />
          <ContentRoute path="/multi_camera/edit/:uuid" component={MultiCameraIndex} auditName="MultiCamera" />
          <ContentRoute path="/multi_camera/report/:uuid" component={MCResLayout} auditName="Multi-Camera Report" />

          {/* Multi scene  */}
          <ContentRoute path="/multiscene/add" component={MultiSceneManagementForm} auditName="Multiscene management" />
          <ContentRoute path="/multiscene" component={MultiSceneDashboard} auditName="Multi scene" />


          {/* User Management */}
          <ContentRoute path="/user-management/add-user" component={AddUser} auditName="User add" />
          <ContentRoute path="/user-management" component={UserManagement} auditName="Users" />


          {/* Events */}
          <ContentRoute path="/events" exact component={AllEvents} auditName="Events" />
          <ContentRoute path="/events/:sys_id/:cameraId" component={EventsPage} auditName="Events" />

          {/* Event management */}
          <ContentRoute path="/event/dashboard" exact component={EventsDashboard} auditName="Eventmanagement" />
          <ContentRoute path="/event/dashboard/:cameraId" component={Index} auditName="Eventmanagement" />

          {/* O-D Matrix */}
          <ContentRoute path="/matrix" component={MatrixPage} auditName="ODMatrix" />


          {/* Tenants */}
          <ContentRoute path="/tenants/add-tenant" component={AddTenant} auditName="Tenant add" />
          <ContentRoute path="/tenants" component={Tenants} auditName="Tenants" />

          {/* Camera management */}
          <ContentRoute path="/admin/cm/edit/:cameraUUID" component={CameraManagementForm} auditName="Camera edit" />
          <ContentRoute path="/admin/cm/advanced/:cameraUUID" component={AdvancedSetting} auditName="Advanced Camera Settings" />


          {/* Live camera */}
          <ContentRoute path="/camera/video/:uuid" component={LiveStream} auditName="Live stream" />

          <ContentRoute path="/admin/cm/add" component={CameraManagementForm} auditName="Camera add" />
          <ContentRoute path="/admin/cm" component={CameraManagementDashboard} auditName="Camera management" />

          {/* Files management */}
          <ContentRoute path="/camera-files/process/:batchUUID" component={ProcessBatch} auditName="Camera files process" />
          <ContentRoute path="/camera-files/edit-batch/:batchUUID" component={UploadFiles} auditName="Camera files edit" />
          <ContentRoute path="/camera-files/edit-files/:batchUUID" component={EditFiles} auditName="Camera files edit files" />
          <ContentRoute path="/camera-files/add-files/:batchUUID" component={AddFilesToBatch} auditName="Camera files add files" />
          <ContentRoute path="/camera-files/new" component={UploadFiles} auditName="Camera files add" />
          <ContentRoute path="/camera-files" component={UploadFilesDashboard} auditName="Camera files" />

          {/* Event logs */}
          <ContentRoute path="/audit/:page?" component={EventLogDashboard} auditName="Audit log" />

          {/* Billing management */}
          <ContentRoute path="/billing" component={BillingComp} auditName="Billing" />

            {/* Documentation */}
            <ContentRoute path="/documentation" component={Documentation} auditName="Billing" />

          {/* settings */}
          <ContentRoute path="/settings" component={PcuSettings} auditName="settings" />
          {/* Page not found */}
          <ContentRoute path="/" component={Error404} />
        </Switch>
      </Suspense>
    </MatrixLayout>
  </EventsLayout>
)

export default BasePage
