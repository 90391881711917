import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import LargeCard from "components/Structure/LargeCard";
import { useQueryLoading } from "hooks/useLoading";
import useCameraManagementAPI from "services/CameraManagementService";
import { LoadingWrapper } from "components";
import { SelectInput } from "components/Reports/Fields";
import { formikDefault } from "utils/forms-schema";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

const AdvancedSetting = () => {
  const configApi = useCameraManagementAPI();
  const { cameraUUID } = useParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [settings, settingsLoadingState] = useQueryLoading(["algo-settings"], () => configApi.getAlgorithmSettings(), [cameraUUID]);
  const [defaultLabels, defaultLabelsLoadingState] = useQueryLoading(["labels", cameraUUID], () => configApi.getCameraAlgorithmSettings(cameraUUID), [
    cameraUUID
  ]);

  const allSettings = convertToJson(settings);

  // Build initialValues once allSettings and defaultLabels are available.
  const initialValues = useMemo(() => {
    if (!allSettings || !defaultLabels) return {};
    return allSettings.reduce((acc, item) => {
      const fieldKey = item.name;
      acc[fieldKey] = defaultLabels[fieldKey] ?? "";
      return acc;
    }, {});
  }, [allSettings, defaultLabels]);

  const formik = useFormik({
    ...formikDefault,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const changedFields = Object.entries(values).reduce((acc, [key, val]) => {
        if (val !== initialValues[key]) {
          acc[key] = val;
        }
        return acc;
      }, {});
      setLoading(true);
      try {
        const res = await configApi.updateCameraAlgorithmSettings(cameraUUID, changedFields);
        setLoading(false);
        setSuccess(res?.success);
        if (success) {
          toast.success("Settings updated successfully");
        }
      } catch (error) {
        toast.error("An error occurred when updating settings");
      }

      // console.log("changeField", changedFields);
    }
  });

  console.log(cameraUUID);

  return (
    <div className="row">
      <div className="col-xl-6 offset-xl-3">
        <LargeCard title="Advanced Camera Settings">
          <LoadingWrapper state={[settingsLoadingState, defaultLabelsLoadingState]}>
            <Form onSubmit={formik.handleSubmit}>
              <div>
                {allSettings &&
                  allSettings?.map((item, index) => {
                    return <SelectInput key={index} name={item.name} label={item.title} options={item.options} formik={formik} />;
                  })}
              </div>

              <Button
                variant="primary"
                type="submit"
                className="float-right"
                disabled={!formik.dirty || formik.isSubmitting}
                style={{
                  cursor: !formik.dirty || formik.isSubmitting ? "not-allowed" : "pointer"
                }}
              >
                Save {loading && <span className="ml-1  spinner spinner-white" />}
              </Button>
            </Form>
          </LoadingWrapper>
        </LargeCard>
      </div>
    </div>
  );
};

export default AdvancedSetting;

function convertToJson(config) {
  if (!config) return;
  const result = Object.keys(config).map((key) => {
    return {
      name: key,
      title: toTitleCase(key.replace(/_/g, " ")),
      options: config[key].map((value) => ({
        value: value,
        label: value
      }))
    };
  });
  return result;
}

function toTitleCase(str) {
  return str
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
}
